<!--
 功能名称:千里眼平台(短信)-营销短信
 开发人:梁高权
 创建时间:2021/05/20
 最后修改时间:2021/05/20
-->
<template>
  <div class="marketingSMS-wrap">
    <NavBar />
    <van-tabs v-model="category_id" color="#FE4600" title-active-color="#FE4600" title-inactive-color="#444745">
      <van-tab v-for="(item, index) in smsTypeList" :key="index" :title="item.category_name" :name="item.id"></van-tab>
    </van-tabs>
    <div class="smsBox">
      <div class="child" v-for="(item, index) in smslist" :key="index">
        <p>{{ item.template }}</p>
        <div>
          <span>本短信{{item.template.length}}字，分{{Math.ceil(item.template.length/60)}}条发送</span>
          <i @click="usingTemplates(item)">使用模板</i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import NavBar from '@/components/NavBar';
import table from '@/api/table';

export default {
  name: 'marketingSMS',
  components: { NavBar },
  data() {
    return {
      category_id: null,
      smsTypeList: [],
      smslist: []
    };
  },
  watch: {
    category_id(newVal) {
      this.templateList(newVal);
    }
  },
  mounted() {
    this.categoryList();
  },
  methods: {
    ...mapMutations(['updateSMScontent']),
    usingTemplates(content){
      console.log(content)
      this.updateSMScontent(content)
      this.$router.push('groupSMS')
    },
    // 获取短信类型
    categoryList() {
      table.categoryList().then(response => {
        this.smsTypeList = response.data.list;
      });
    },
    // 短信-列表
    templateList(category_id) {
      table.templateList({ category_id }).then(response => {
        this.smslist = response.data.list;
        console.log(response.data.list);
      });
    }
  }
}

</script>

<style lang="scss" scoped>
.marketingSMS-wrap {
  .smsBox {
    padding: 20px 30px;
    .child {
      background: #fff;
      padding: 27px 35px;
      border-radius: 16px;
      margin-bottom:20px;
      p {
        color: #333333;
        font-size: 26px;
      }
      >div{
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        span{
          font-size: 24px;
          font-weight: 500;
          color: #999999;
        }
        i{
          font-size: 24px;
          font-weight: bold;
          color: #2C6BF8;
        }
      }
    }
  }
}
</style>
